import AuthService from "@/services/AuthService";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import Gard from "@/services/Middleware";
import axios from "axios";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: [Gard.authenticateuser],
    children: [
      {
        // the 401 route, when none of the above matches
        path: "/dashboard",
        name: "index",
        components:{
          default: () => import("@/views/dashboard/Dashboard.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterDashboard.vue')
        },
        meta : {nivelCargo : [100, 150, 200, 300, 400, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/filtroVeiculos",
        name: "filter",
        components:{
          default: () => import("@/views/filter/FiltroVeiculos.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterFiltroVeiculos.vue')
        }, 
        meta : {nivelCargo : [100, 150]},
      },
      {
        path: "/gestaoLubrificantes",
        name: "filterLub",
        component: () => import("@/views/filter/FiltroLubrificantes.vue"),
        meta : {nivelCargo : [100, 150]},
      },
      {
        path: "/produtosFracionados",
        name: "ProdutosFracionados",
        component: () => import("@/views/fracionados/ProdutosFracionados.vue"),
        meta : {nivelCargo : [100, 150]},
      },
      {
        path: "/bandeiras",
        name: "Bandeiras",
        component: () => import("@/views/bandeiras/Bandeiras.vue"),
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/lojas",
        name: "store",
        components:{ 
          default:() => import("@/views/store/Store.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterStore.vue')},
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/cardapio",
        name: "cardapio",
        component: () => import("@/views/cardapio/Cardapio.vue"),
        //components:{ 
          //default:() => import("@/views/cardapio/Cardapio.vue"),
          // filter: () => import('@/layout/header/partials/filters/FilterStore.vue')},
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/planos",
        name: "planos",
        components:{ 
          default:() => import("@/views/planos/Planos.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterPlanos.vue')},
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "lojas/atendimentos",
        name: "attendance",
        components:{
        default: () => import("@/views/store/Attendance.vue"),
        filter: () => import('@/layout/header/partials/filters/FilterAttendance.vue')
        }, 
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "usuarios",
        name: "users",
        components:{
        default: () => import("@/views/usersEdit/Users.vue"),
        filter: () => import('@/layout/header/partials/filters/FilterUsers.vue')
        }, 
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "lojas/atendimentos/detalhes",
        name: "details",
        component: () => import("@/views/store/AttendanceDetails.vue"),
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/veiculos",
        name: "veiculos",
        components:{
          default: () => import("@/views/vehicles/vehicles.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterVehicles.vue'),
        },
        // component: () => import("@/views/vehicles/vehicles.vue"),
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/veiculosRevisao",
        name: "veiculosRevisao",
        components:{
          default: () => import("@/views/veiculosRevisao/VeiculosRevisao.vue"),
          // filter: () => import('@/layout/header/partials/filters/FilterRevisaoVehicles.vue')
        },
        // component: () => import("@/views/vehicles/vehicles.vue"),
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "lojas//produtos",
        name: "Produtos",
        component: () => import("@/views/products/Produtos.vue"),
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/dashboard",
        name: "dashboard",
        components:{
          default: () => import("@/views/dashboard/Dashboard.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterDashboard.vue')
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/planilhas",
        name: "uploadPlanilhas",
        components:{
          default: () => import("@/views/planilhas/UploadPlanilhas.vue"),
          // filter: () => import('@/layout/header/partials/filters/FilterDashboard.vue')
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/planilhasVeiculos",
        name: "uploadPlanilhasVeiculos",
        components:{
          default: () => import("@/views/planilhaVeiculo/UploadPlanilhasVeiculo.vue"),
          // filter: () => import('@/layout/header/partials/filters/FilterDashboard.vue')
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/uploadLubrificanteTransmissao",
        name: "UploadPlanilhaLubrificanteTransmissao",
        components:{
          default: () => import("@/views/planilhaLubrificanteTransmissao/uploadPlanilhaLubrificanteTransmissao.vue"),
        },
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/logs/produtos",
        name: "logProdutos",
        components:{
          default: () => import("@/views/logs/LogProdutos.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterLogProdutos.vue'),
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/cadastros/grupos",
        name: "grupos",
        components:{
          default: () => import("@/views/grupos/Grupos.vue"),
          // filter: () => import('@/layout/header/partials/filters/FilterGrupos.vue'),
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/cadastros/grupos/editar",
        name: "editarGrupos",
        components:{
          default: () => import("@/views/grupos/EditarGrupo.vue"),
          // filter: () => import('@/layout/header/partials/filters/FilterGrupos.vue'),
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/lojasBloqueadas",
        name: "lojasBloqueadas",
        components:{
          default: () => import("@/views/lojasBloqueadas/LojasBloqueadas.vue"),
          // filter: () => import('@/layout/header/partials/filters/FilterGrupos.vue'),
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/relatorios/buscaPlaca",
        name: "RelatorioBuscaPlaca",
        components:{
          default: () => import("@/views/relatorios/RelatorioBuscaPlaca.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterRelatory.vue'),
        },
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/relatorios/consultas",
        name: "RelatorioConsultas",
        components:{
          default: () => import("@/views/relatorios/RelatorioConsultas.vue"),
        },
        meta : {nivelCargo : [100, 150]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/relatorios/consultas/loja/:cnpj/:codGrupo/:dataInput",
        name: "RelatorioConsultasLoja",
        components:{
          default: () => import("@/views/relatorios/RelatorioConsultasLoja.vue"),
        },
        meta : {nivelCargo : [100, 150]},
      },
      {
        path: "/usuariosIpiranga",
        name: "usuariosIpiranga",
        components:{
          default: () => import("@/views/usuarioIpiranga/UsuarioIpiranga.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterIpirangaUsers.vue'),
        },
        meta : {nivelCargo : [100, 150]},
      },
      {
        path: "/lojasValidacaoJetoil",
        name: "lojasValidacaoJetoil",
        components:{
          default: () => import("@/views/lojasValidacaoJetoil/LojasValidacaoJetoil.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterLojasValidacaoJetoil.vue'),
        },
        meta : {nivelCargo : [100, 150]},
      },
      {
        path: "/produtosIpiranga",
        name: "ProdutosIpiranga",
        components:{
          default: () => import("@/views/produtosIpiranga/ProdutosIpiranga.vue"),
        },
        meta : {nivelCargo : [100, 150]},
      },
      {
        path: "/gestaoApiConecta",
        name: "GestaoApiConecta",
        components:{
          default: () => import("@/views/gestaoApiConecta/GestaoApiConecta.vue"),
        },
        meta : {nivelCargo : [100, 150]},
      },
      {
        path: "/custosApp",
        name: "custosApp",
        components:{
          default: () => import("@/views/custosApp/CustosApp.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterCustos.vue'),
        },
        meta : {nivelCargo : [100, 150]},
      },
      {
        path: "/usoApp",
        name: "usoApp",
        components:{
          default: () => import("@/views/usoApp/UsoApp.vue"),
          // filter: () => import('@/layout/header/partials/filters/FilterCustos.vue'),
        },
        meta : {nivelCargo : [100, 150]},
      },
      {
        path: "/relatorios/downloadRetornoConsumidor",
        name: "downloadRetornoConsumidor",
        components:{
        },
        beforeEnter: async (to, from, next) => {
          try {
            const url = `${process.env.VUE_APP_URL_API}relatorio/downloadRetornoConsumidor`;
            window.open(url, "_blank");
          } catch (error) {
            console.error("Erro ao baixar o arquivo:", error);
            next("/login"); // Substitua "/erro" pela rota desejada em caso de erro
          }
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Login.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
        beforeEnter: [Gard.redirectAuthenticatedUser],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/RecuperarSenha.vue"),
    children: [
      {
        path: "/recuperarSenha",
        name: "recuperarSenha",
        component: () => import("@/views/RecuperarSenha.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/RedefinirSenha.vue"),
    children: [
      {
        path: "/redefinirSenha",
        name: "redefinirSenha",
        component: () => import("@/views/RedefinirSenha.vue"),
      },
    ],
  },
  {
    // rota não autenticada para visualização do andamento da implantação
    path: "/acompanhamentoImplantacao",
    name: "acompanhamentoImplantacao",
    component: () => import("@/views/acompanhamentoImplantacao/AcompanhamentoImplantacao.vue"),
    meta: { requiresAuth: false },
  },
  {
    // the 401 route, when none of the above matches
    path: "/401",
    name: "401",
    component: () => import("@/views/Error401.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.body.classList.add("page-loading");
  document.body.classList.remove("modal-open");
  document.body.style.overflow = "auto";
  document.body.style.paddingRight = "0";

  // Evita o loop na chamada
  if (to.path !== '/login') {
    try {
      // Verifica se a rota requer autenticação
      if (to.matched.some(record => record.meta.requiresAuth)) {
        const niveisCargo: any = to.meta.nivelCargo;
        const usuario = JSON.parse(AuthService.getUsuario());
        console.log('user', usuario.nivelCargo);

        if (!usuario.nivelCargo || !niveisCargo.includes(usuario.nivelCargo)) {
          store.dispatch(Actions.LOGOUT);
          return next('/login');
        }
      }

    } catch (error) { // Caso ocorra algum erro na validação o usuário será deslogado.
      console.log('[ERRO]', error);
      store.dispatch(Actions.LOGOUT);
      return next('/login');
    }
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  next(); // Certifica-se de que o ciclo de navegação continue
});


router.afterEach((to, from) => {
  document.body.classList.remove("page-loading");
});

export default router;
